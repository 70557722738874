<template>
  <div>
    <div class="mobileNav">Payment</div>
    <b-container>
      <b-row class="p-3">
        <b-col md="6" sm="12" class="p-4">
          <h3 class="mb-4">MAKE A PAYMENT</h3>
          <b-row>
            <b-col sm="12" md="8">
              <div class="bg-blue text-white p-3 mycard">
                <!-- Pay Balance Only -->
                <!-- <div
                    v-show="data.tp_capacity === 0 && data.ex_subtotal === 0"
                  >
                    <h4 class="m-2">Pay Balance</h4>
                  </div> -->

                <!-- Data Top Up Only -->
                <div v-show="data.ex_subtotal === 0 && data.tp_capacity !== 0">
                  <h4 class="m-2">{{ data.tp_capacity }} GB Data Top Up</h4>
                </div>
                <!-- Extension Only  -->
                <div v-show="data.ex_subtotal !== 0 && data.tp_capacity === 0">
                  <h4 class="m-2">{{ data.duration }} Days Extension</h4>
                </div>
                <h2 class="m-2">$ {{ data.amount }} {{ data.currency }}</h2>
              </div>
            </b-col>
          </b-row>
          <p class="p-2 mt-2 mb-0 w-500">
            Please choose your payment method:
          </p>
          <!-- credit card  -->
          <b-row>
            <b-col md="8" sm="12" class="my-2">
              <div
                class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                @click="handlePayment('creditcard')"
              >
                <i class="fas fa-credit-card"></i>
                <p class="m-0">Credit Card</p>
              </div>
            </b-col>
          </b-row>
          <!-- pay pal  -->
          <b-row>
            <b-col md="8" sm="12" class="my-2">
              <div
                class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                @click="handlePayment('paypal')"
              >
                <i class="fab fa-paypal"></i>
                <p class="m-0">PayPal</p>
              </div>
            </b-col>
          </b-row>
          <!-- ali pay  -->
          <b-row v-show="data.currency === 'CAD'">
            <b-col md="8" sm="12" class="my-2">
              <div
                class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                @click="handlePayment('alipay_qr')"
              >
                <i class="fab fa-alipay"></i>
                <p class="m-0">AliPay</p>
              </div>
            </b-col>
          </b-row>
          <!-- we chat pay  -->
          <b-row v-show="data.currency === 'CAD'">
            <b-col md="8" sm="12" class="my-2">
              <div
                class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                @click="handlePayment('wx_native')"
              >
                <i class="fab fa-weixin"></i>
                <p class="m-0">WeChat Pay</p>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <!-- right part  -->
        <b-col md="6" sm="12" class="p-4">
          <p class="w-500 mt-5">Payment Options:</p>
          <p>
            You have following options for payment for your PhoneBox account.
          </p>
          <ul>
            <li>
              At any one of our PhoneBox locations where we accpet cash, cheque,
              credit card and debit cards for payment.
            </li>
            <li>
              Pay using online banking at your bank. Available with all Canadian
              banks.
            </li>

            <li>
              Pay online at self-serve account with your credit card.
            </li>
          </ul>
          <!-- <p class="w-500">
              We accept almost every credit card including international credit
              cards.
            </p> -->
        </b-col>
      </b-row>
    </b-container>

    <el-dialog
      :visible.sync="dialogVisible"
      :width="isMobileOrTablet ? '90%' : '30%'"
    >
      <div class="text-center">
        <i
          style="font-size: 60px"
          :style="isAlipay ? 'color:#31A3EA' : 'color:#289B37'"
          class="fab fa-alipay"
          :class="isAlipay ? 'fa-alipay' : 'fa-weixin'"
        ></i>
        <h3 class="my-2">
          $ {{ data.amount }}
          {{ data.systag === "acctarea/topup/usd" ? "USD" : "CAD" }}
        </h3>
        <p class="my-2">
          Please scan the QR code to finish payment using your
          {{ isAlipay ? "Alipay" : "WeChat" }} App
        </p>
        <div>
          <b-img :src="qrCodeUrl"></b-img>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PrepaidPayment",
  data() {
    return {
      dialogVisible: false,
      chinesePaymentMethod: "", //control ui
      isMobileOrTablet: false,
      gintTimerHandle: 0,
      gstrPayId: "",
      qrCodeUrl: "",
    };
  },
  computed: {
    isAlipay() {
      return this.chinesePaymentMethod[0] === "a";
    },
    data() {
      // prepaid service data general format
      return this.$store.state.prepaidServiceData;
    },
  },
  watch: {
    dialogVisible(value) {
      if (!value) {
        clearInterval(this.gintTimerHandle);
      }
    },
  },
  methods: {
    processApiUrl(str) {
      let newStr = "";
      for (let chr of str) {
        let newChr = chr;
        if (newChr === ":") {
          newChr = "%3A";
        } else if (chr === "/") {
          newChr = "%2F";
        }
        newStr += newChr;
      }
      return newStr;
    },
    checkStatus() {
      if (this.gstrPayId) {
        this.$axios
          .get("Billing/GetIotPayStatus?payId=" + this.gstrPayId)
          .then((response) => {
            if (response.data == "Approved") {
              // this.getAccountInfo();
              this.dialogVisible = false;
              this.gstrPayId = "";
              clearInterval(this.gintTimerHandle);
              this.$notify({
                title: "Success",
                message: "Thank you for your payment !",
                type: "success",
                duration: 0,
                onClose: () => {
                  this.$router.go();
                },
              });
              setTimeout(() => {
                this.$router.push("/prepaid-home");
              }, 5000);
            }
          })
          .catch(() => {
            clearInterval(this.gintTimerHandle);
          });
      }
    },
    handlePayment(value) {
      this.$store.commit("setLoadingTrue");
      let currentUrl = window.location.href;
      let homeUrl =
        currentUrl.substring(0, currentUrl.length - 15) + "prepaid-home";
      // type is payment type
      let type = value;
      if (this.isMobileOrTablet && type === "alipay_qr") {
        type = "alipay_wap";
      }
      this.data.paymentMethod = type;
      let url =
        "?return_url=" +
        this.processApiUrl(homeUrl) +
        "&cancel_url=" +
        this.processApiUrl(currentUrl);
      // call the prepaid api
      this.$axios
        .post("Prepaid/TopUpExtension" + url, this.data)
        .then((response) => {
          this.$store.commit("setLoadingFalse");
          if (response.data.substring(0, 6) === "Failed") {
            console.log(response.data);
            this.$notify.error({
              title: "Error !",
              message: "Payment Error !",
            });
          } else {
            let redirectUrl = response.data;
            if (type === "alipay_qr" || type === "wx_native") {
              this.chinesePaymentMethod = type;
              let data = redirectUrl.split("^");
              if (data.length == 2) {
                this.gstrPayId = data[0];
                // console.log("image gstrPayId=" + this.gstrPayId);
                this.qrCodeUrl = data[1];
                this.gintTimerHandle = setInterval(this.checkStatus, 1000);
              } else {
                console.log("image wrong format");
              }
              this.dialogVisible = true;
            } else {
              location.href = redirectUrl;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setLoadingFalse");
          this.$notify.error({
            title: "Error !",
            message: "Payment Error !",
          });
        });
    },
  },
  mounted() {
    this.isMobileOrTablet = window.innerWidth <= 1024;
  },
};
</script>

<style lang="scss" scoped>
.creditCard {
  cursor: pointer;
}
</style>
